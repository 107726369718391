import {
  UpdateTerminalInput,
  MutationUpdateTerminalArgs,
  Terminal,
  TerminalCategory,
} from "@/graphql/types";
import { gql } from "@apollo/client";
import { TERMINAL_FIELDS } from "@/graphql/terminal/terminals";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { getActiveTerminal } from "@/graphql/sequence/active.sequence";
import { CONSTANTS, trim } from "@/graphql/utils/utils";
import { ref } from "vue";
import { clone } from "chart.js/helpers";

type UpdateTerminalData = {
  updateTerminal: Terminal;
};
type Selection = {
  checked: boolean;
  partialChecked: boolean;
};
type SelectedKeys = Record<string, Selection>;

const UPDATE_TERMINAL = gql`
    mutation UpdateTerminal($input: UpdateTerminalInput!){
        updateTerminal(input:$input){
            ${TERMINAL_FIELDS}
        }
    }
`;
export const useUpdateTerminal = () => {
  const {
    loading: updateLoading,
    mutate,
    onDone,
  } = useMutation<UpdateTerminalData, MutationUpdateTerminalArgs>(
    UPDATE_TERMINAL
  );
  const toast = useToast();
  const { t } = useI18n();
  const selectedKeys = ref<SelectedKeys>({});
  const userIds = ref<number[]>([]);

  const selected = ref<Terminal>();
  setSelectedKeys(selected.value?.categories);
  function setSelected(terminal: Terminal) {
    selected.value = clone(terminal);
    setSelectedKeys(terminal.categories);
  }

  onDone(({ data }) => {
    const res = data?.updateTerminal;
    if (res) {
      toast.add({
        severity: "success",
        summary: t("terminal.updateTerminal"),
        detail: t(`terminal.update.success`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
      if (data.updateTerminal.id === getActiveTerminal()?.id)
        localStorage.setItem(
          CONSTANTS.activeTerminal,
          JSON.stringify(data.updateTerminal)
        );
    } else {
      toast.add({
        severity: "warn",
        summary: t("terminal.updateTerminal"),
        detail: t(`terminal.update.failed`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });
  function updateTerminal() {
    const input: UpdateTerminalInput = {
      active: selected.value.active,
      codeBar: selected.value.codeBar,
      printBarcode: selected.value.printBarcode,
      printStockPos: selected.value.printStockPos,
      allowDiscountLines: selected.value.allowDiscountLines,
      categories: [],
      margeActive: selected.value.margeActive,
      description: selected.value.description,
      id: selected.value.id,
      name: selected.value.name,
      printerSettings: selected.value.printerSettings,
      printerMargins: selected.value.printerMargins,
      userIds: selected.value.users.map((u) => u.id),
    };
    trim(input);
    Object.entries(selectedKeys.value).forEach((value) => {
      if (value[1].checked || value[1].partialChecked) {
        input.categories.push({
          categoryId: Number(value[0]),
          ...value[1],
        });
      }
    });
    void mutate({ input });
  }
  function setSelectedKeys(terminalCategories: TerminalCategory[]) {
    selectedKeys.value = {};
    userIds.value.length = 0;
    if (terminalCategories) {
      terminalCategories.forEach((v) => {
        selectedKeys.value = {
          ...selectedKeys.value,
          [String(v.categoryId)]: {
            checked: v.checked,
            partialChecked: v.partialChecked,
          },
        };
      });
    }
  }
  return {
    updateLoading,
    updateTerminal,
    selectedKeys,
    setSelected,
    selected,
  };
};
