import {
  MutationSequenceCheckActiveTerminalArgs,
  Terminal,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";

type SequenceCheckActiveTerminalData = {
  sequenceCheckActiveTerminal: Terminal;
};

const SEQUENCE_CHECK_ACTIVE_TERMINAL = gql`
  mutation SequenceCheckActiveTerminal($terminalId: String!) {
    sequenceCheckActiveTerminal(terminalId: $terminalId) {
      id
      number
    }
  }
`;

export const useSequenceCheckActiveTerminal = (callback: () => void) => {
  const toast = useToast();
  const { t } = useI18n();
  const {
    loading: loadingCheckActive,
    mutate,
    onDone,
  } = useMutation<
    SequenceCheckActiveTerminalData,
    MutationSequenceCheckActiveTerminalArgs
  >(SEQUENCE_CHECK_ACTIVE_TERMINAL);

  onDone(({ data }) => {
    if (data?.sequenceCheckActiveTerminal?.id) {
      toast.add({
        severity: "warn",
        summary: t("terminal.hasActiveSequence"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else callback();
  });

  function checkActive(terminalId: string) {
    void mutate({ terminalId });
  }

  return { loadingCheckActive, checkActive };
};
